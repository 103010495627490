// development
// export const apiProxy = "http://127.0.0.1:4000"
// export const assetsURL = "http://127.0.0.1:4000/"


// production
export const apiProxy = "https://api-padel91.ultime-manager.com"
export const assetsURL = "https://api-padel91.ultime-manager.com/"

// export const apiProxy = "https://api.padel91.fr"
// export const assetsURL = "https://api.padel91.fr/"